import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./App.css";
import "./style.css";
import "./Responsive.css";
import "./polygon.css";
import "rsuite/dist/rsuite.css";
import RouterPage from "../src/router/RouterPage";
import { ProfileProvider } from "../src/components/context/ProfileContext";

function App() {
  return (
    <div className="App">
      <ProfileProvider>
        <RouterPage />
      </ProfileProvider>
    </div>
  );
}

export default App;
