import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../components/navbar/NavBar";
import MoonPayView from "../../moonpay/MoonPayView";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import Swal from "sweetalert2";
import Tooltip from "@mui/material/Tooltip";
import { Connection, LAMPORTS_PER_SOL } from "@solana/web3.js";
import LiquidityPool from "../../../components/liquidity-pool/Swap_token";
import { calculateExchangeRate } from "../../../utils/helper";
import phantomIcon from "../../../assets/images/phantom.png";

const rpc = process.env.REACT_APP_RPC_URL;

const connection = new Connection(rpc);

const Purchase = () => {
  const [loading, setLoading] = useState(true);
  const [rate, setRate] = useState(0);
  const [inputs, setInputs] = useState({ sol: "", lfc: "" });
  const [error, setError] = useState("");
  const [buyAmount, setBuyAmount] = useState("");
  const [wallet, setWallet] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [connected, setConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [connectedAccounts, setConnectedAccounts] = useState(new Set());
  const [balance, setBalance] = useState(0);
  const [isConnected, setIsConnected] = useState(null);
  const navigate = useNavigate();

  const [isSwapped, setIsSwapped] = useState(false);

  const swapContainers = () => {
    setIsSwapped((prev) => !prev);
  };

  useEffect(() => {
    if ("solana" in window && window.solana.isPhantom) {
      const provider = new PhantomWalletAdapter();
      setWallet(provider);
    } else {
      console.error("Wallet not detected");
    }
  }, []);

  const API_URL = "https://api.binance.com/api/v3/ticker/price?symbol=SOLUSDT";

  useEffect(() => {
    axios
      .get(API_URL)
      .then((response) => {
        const responseData = response.data.price;
        const numericResult = Math.round(responseData * 1000) / 1000;
        setRate(numericResult);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Solana price:", error);
        setLoading(false);
      });
  }, []);

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    const newInputs = { ...inputs };
    const numValue = parseFloat(value);
    newInputs[name] = value;
    
    if (name === "sol") {
      const [rate, fee, burn_fee] = await calculateExchangeRate(value);
      newInputs.lfc = rate.toFixed(6);
    }
    else if (name === "lfc") {
      newInputs.sol = "";
    }
    console.log(newInputs, "--------------- newInputs");
    setInputs(newInputs);
  };

  const handleBuySolAmountChange = (event) => {
    setBuyAmount(event.target.value);
  };

  const connectWallet = async () => {
    if (wallet) {
      try {
        await wallet.connect();

        setWalletAddress(wallet.publicKey.toString());
        setConnectedAccounts((prev) =>
          new Set(prev).add(wallet.publicKey.toString())
        );
        setIsConnected(wallet.publicKey);
        setConnected(true);
        await updateBalance(wallet.publicKey);
      } catch (err) {
        console.error("Failed to Connect");
      }
    } else {
      Swal.fire({
        title: "Error!",
        text: "No wallet found",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const disconnectWallet = async () => {
    if (wallet) {
      try {
        await wallet.disconnect();
        setConnected(false);
        setWalletAddress("");
        setIsConnected(null);
      } catch (error) {
        console.error("Failed to connect wallet");
      }
    } else {
      Swal.fire({
        title: "Error!",
        text: "No wallet found",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleAccountChange = async (newPublicKey) => {
    // console.log("Account changed to:", newPublicKey.toBase58());
    if (!connectedAccounts.has(newPublicKey)) {
      setConnected(false);
      setWalletAddress("");
      setIsConnected(null);
    } else {
      await connectWallet(newPublicKey);
    }
  };

  useEffect(() => {
    if (window.solana) {
      window.solana.on("accountChanged", handleAccountChange);
    }

    return () => {
      if (window.solana) {
        window.solana.removeListener("accountChanged", handleAccountChange);
      }
    };
  }, []);

  const updateBalance = async (publicKey) => {
    const balance = await connection.getBalance(publicKey);
    setBalance(balance / LAMPORTS_PER_SOL);
  };

  const copyAccountToClipboard = () => {
    navigator.clipboard.writeText(walletAddress || "").then(
      function () {
        console.log("Copying to clipboard was successful!");
      },
      function (err) {
        Swal.fire({
          title: "Error!",
          text: "Could not copy text: ",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    );
  };

  const handleRightToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <NavBar />
      <div>
        <div className="user-dashboard">
          <div className="right-sidebar">
            <div className="right-sidebar-inner">
              <nav className="navbar navbar-expand-lg navbar-light">
                <div className="right-toggle-menu-outer">
                  <div className="right-toggle-menu">
                    <div
                      className="right-toggle-menu-inner"
                      onClick={handleRightToggle}
                    >
                      <div className="wallent">
                        {walletAddress ? (
                          <button className="btn btn-one">
                            {walletAddress.substring(0, 4)}...
                            {walletAddress.slice(-4)}
                          </button>
                        ) : (
                          <button className="btn btn-two">Connect</button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
              <div
                className={`right-sidebar ${isOpen === true ? "active" : ""}`}
              >
                <div className="sd-header">
                  <div className="sd-header-btn" onClick={handleRightToggle}>
                    <i className="fa fa-times"></i>
                  </div>
                </div>
                <div className="sd-body">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <div className="connect-card">
                        <div className="card-body">
                          <div className="card-inner">
                            <div className="wallet-ui">
                              <div className="wallet-add">
                                <h6
                                  style={{
                                    color: walletAddress ? "green" : "red",
                                    paddingRight: 10,
                                  }}
                                >
                                  {walletAddress ? "Wallet Address " : ""}
                                  {walletAddress
                                    ? `${walletAddress.substring(
                                        0,
                                        4
                                      )}...${walletAddress.slice(-4)}`
                                    : "Wallet not connected"}
                                </h6>
                                {walletAddress ? (
                                  <Tooltip title="Copy Wallet Address" arrow>
                                    <FontAwesomeIcon
                                      icon={faCopy}
                                      style={{ cursor: "pointer" }}
                                      onClick={copyAccountToClipboard}
                                    />
                                  </Tooltip>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div style={{ marginLeft: "10px" }}>
                                {connected ? (
                                  <p>Balance: {balance} SOL</p>
                                ) : (
                                  <p>Balance: 0 SOL</p>
                                )}
                              </div>
                              <div className="wallet-btn">
                                {connected ? (
                                  <button
                                    className="btn"
                                    onClick={disconnectWallet}
                                  >
                                    Disconnect Wallet
                                  </button>
                                ) : (
                                  <button
                                    className="btn connect-wallet-btn"
                                    onClick={connectWallet}
                                  >
                                    <img
                                      src={phantomIcon}
                                      width="40"
                                      height="40"
                                    />{" "}
                                    Connect Wallet
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`sidebar-overlay ${isOpen === true ? "active" : ""}`}
                onClick={handleRightToggle}
              ></div>
            </div>
          </div>
          <div className="user-dashboard-inner">
            <div className="user-dashboard-tab">
              <div className="container">
                <div className="user-dashboard-tab-inner">
                  <Tabs
                    defaultActiveKey="swap"
                    id="uncontrolled-tab-example"
                    className=""
                  >
                    <Tab eventKey="swap" title="Swap">
                      <div className="mb-3">
                        <h6>Current Solana price is ${rate}</h6>
                      </div>
                      <div className="container-wrapper">
                        {isSwapped ? (
                          <>
                            <div className="user-dash-tab-form">
                              <Form.Label>
                                Buy LAL Coin<span>*</span>
                              </Form.Label>
                              <InputGroup className="">
                                <Form.Control
                                  type="number"
                                  aria-label="Number input with dropdown button"
                                  value={inputs.lfc}
                                  onChange={handleInputChange}
                                  name="lfc"
                                  placeholder="LAL amount"
                                />
                              </InputGroup>
                            </div>

                            <div className="down-arrow-tab">
                              <span
                                onClick={swapContainers}
                                style={{ cursor: "pointer" }}
                              >
                                <i class="fa-solid fa-down-long"></i>
                                <i class="fa-solid fa-up-long"></i>
                              </span>
                            </div>

                            <div className="user-dash-tab-form">
                              <Form.Label>
                                Sell Solana<span>*</span>
                              </Form.Label>
                              <InputGroup className="">
                                <Form.Control
                                  type="number"
                                  aria-label="Number input with dropdown button"
                                  value={inputs.sol}
                                  onChange={handleInputChange}
                                  name="sol"
                                  placeholder="Solana amount"
                                />
                                {error && <div>{error}</div>}
                              </InputGroup>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="user-dash-tab-form">
                              <Form.Label>
                                Sell Solana<span>*</span>
                              </Form.Label>
                              <InputGroup className="">
                                <Form.Control
                                  type="number"
                                  aria-label="Number input with dropdown button"
                                  value={inputs.sol}
                                  onChange={handleInputChange}
                                  name="sol"
                                  placeholder="Solana amount"
                                />
                              </InputGroup>
                            </div>

                            <div className="down-arrow-tab">
                              <span
                                onClick={swapContainers}
                                style={{ cursor: "pointer" }}
                              >
                                <i class="fa-solid fa-up-long"></i>
                                <i class="fa-solid fa-down-long"></i>
                              </span>
                            </div>

                            <div className="user-dash-tab-form">
                              <Form.Label>
                                Buy LAL Coin<span>*</span>
                              </Form.Label>
                              <InputGroup className="">
                                <Form.Control
                                  type="number"
                                  aria-label="Number input with dropdown button"
                                  value={inputs.lfc}
                                  onChange={handleInputChange}
                                  name="lfc"
                                  placeholder="LAL amount"
                                />
                              </InputGroup>
                            </div>
                          </>
                        )}
                      </div>

                      <LiquidityPool
                        solAmount={inputs.sol}
                        _wallet={isConnected}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        setInput={setInputs}
                      />

                      <div>
                        <div className="mb-2">
                          <h6>Disclaimer:</h6>
                        </div>
                        <p>Note: Each transaction you perform, there will be</p>
                        <p>Swap Fee: 0.2%</p>
                        <p>Burn Fee: 0.05%</p>
                      </div>
                    </Tab>
                    <Tab eventKey="send" title="Send">
                      <div className="send-tab">
                        <p>$00</p>
                      </div>

                      <Form.Select aria-label="Default select example">
                        <option>Menu</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                        <option value="4">Four</option>
                        <option value="5">Five</option>
                        <option value="6">Six</option>
                      </Form.Select>

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>To</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Wallet address or ENS Number"
                        />
                      </Form.Group>

                      <div className="connect-wallet-one">
                        <Button variant="">Connect Wallet</Button>
                      </div>
                    </Tab>
                    <Tab eventKey="buy" title="Buy">
                      <div className="send-tab">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <h4>Buy Solana</h4>
                          {loading ? (
                            <p>Loading...</p>
                          ) : (
                            <div>
                              <h6>Current Solana price is ${rate}</h6>
                            </div>
                          )}
                          <Form.Label>
                            Amount in Dollar<span>*</span>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter amount"
                            value={buyAmount}
                            onChange={handleBuySolAmountChange}
                            required
                          />
                        </Form.Group>
                      </div>
                      <div className="swap-btn">
                        <MoonPayView
                          userInput={buyAmount}
                          wallet={isConnected}
                          isOpen={isOpen}
                          setIsOpen={setIsOpen}
                          setBuyAmount={setBuyAmount}
                          walletAddress={walletAddress}
                        />
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Purchase;
