import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import NavBar from "../../components/navbar/NavBar";
import Swal from "sweetalert2";
import { ProfileContext } from "../../components/context/ProfileContext";

const Profile = () => {
  const {
    setUserProfileImage,
    setAdminProfileImage,
    setUserFirstname,
    setUserLastname,
    setAdminFirstname,
    setAdminLastname,
  } = useContext(ProfileContext);
  const [firstname, setFirstNameLocal] = useState("");
  const [lastname, setLastNameLocal] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDOB] = useState("");
  const [description, setDescription] = useState("");
  const [role, setRole] = useState("");
  const [profileImage, setProfileImageLocal] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);

  const User = localStorage.getItem("user");
  const parsedUser = JSON.parse(User);
  const token = parsedUser.auth;

  useEffect(() => {
    if (token) {
      const role = parsedUser.role;
      setRole(role);

      const endpoint =
        role === "admin" ? "/admin/getAdminProfile" : "/users/getUserProfile";

      axios
        .get(`${process.env.REACT_APP_API_ENDPOINT_URL}${endpoint}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.data.status === 200) {
            const userData = response.data.data;
            setFirstNameLocal(capitalizeFirstLetter(userData.firstName));
            setLastNameLocal(capitalizeFirstLetter(userData.lastName));
            setEmail(userData.email);
            setAddress(userData.address);
            setCity(userData.city);
            setState(userData.state);
            setZip(userData.zip);
            setPhone(userData.phone);
            setCountry(userData.country);
            const formattedDOB = formatDOB(userData.dob);
            setDOB(formattedDOB);
            setDescription(userData.description);

            if (
              userData.profileImage &&
              typeof userData.profileImage === "string"
            ) {
              try {
                const decodedData = Buffer.from(
                  userData.profileImage.replace(/^data:.*,/, ""),
                  "base64"
                );

                const uint8Array = new Uint8Array(decodedData.length);
                for (let i = 0; i < decodedData.length; i++) {
                  uint8Array[i] = decodedData[i];
                }

                const blob = new Blob([uint8Array]);

                const imgSrc = URL.createObjectURL(blob);
                setImageBase64(imgSrc);
              } catch (err) {
                console.error("Failed to decode base64 image:", err);
              }
            } else {
              console.warn("Invalid profileImage format");
            }
          } else {
            Swal.fire({
              title: "Error!",
              text: `${response.data.message}`,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch(() => {
          Swal.fire({
            title: "Error!",
            text: "Something Went Wrong!",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  }, [token, parsedUser.role]);

  const handleUpdateProfile = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("firstname", firstname);
    formData.append("lastname", lastname);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("zip", zip);
    formData.append("phone", phone);
    formData.append("dob", dob);
    formData.append("country", country);
    formData.append("description", description);

    if (profileImage) {
      formData.append("image", profileImage);
    }

    const endpoint =
      role === "admin"
        ? "/admin/updateAdminProfile"
        : "/users/updateUserProfile";

    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT_URL}${endpoint}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          Swal.fire({
            title: "Success!",
            text: "Your profile is successfully updated",
            icon: "success",
            confirmButtonText: "OK",
          });
          if (role === "admin") {
            setAdminProfileImage(imageBase64);
            setAdminFirstname(firstname);
            setAdminLastname(lastname);
          } else {
            setUserProfileImage(imageBase64);
            setUserFirstname(firstname);
            setUserLastname(lastname);
          }
        } else {
          Swal.fire({
            title: "Error!",
            text: `${response.data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: "Error!",
          text: "Something Went Wrong! Please try again",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setProfileImageLocal(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImageBase64(reader.result);
      if (role === "admin") {
        setAdminProfileImage(reader.result);
      } else {
        setUserProfileImage(reader.result);
      }
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const formatDOB = (dob) => {
    const date = new Date(dob);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const imageButtonText = profileImage || imageBase64 ? "Update" : "Upload";

  return (
    <>
      <div className="wrapper">
        <NavBar />
        <div className="container-dashboard-right">
          <p className="content-title">Profile</p>
          <form onSubmit={handleUpdateProfile}>
            <div className="profile-main-wrapper">
              <div className="profile-wrapper">
                <div className="profile_image-and-detail">
                  <div className="profile-img-header">
                    <div className="avatar-upload">
                      <div className="avatar-edit">
                        <input
                          type="file"
                          id="imageUpload"
                          accept="image/*"
                          onChange={(e) => handleFileChange(e)}
                          style={{ display: "none" }}
                        />
                      </div>
                      <div className="avatar-preview">
                        {imageBase64 && (
                          <img
                            src={imageBase64}
                            alt="User"
                            style={{
                              width: "135px",
                              height: "135px",
                              background: "#ccc",
                              borderRadius: "50%",
                            }}
                          />
                        )}
                        {!imageBase64 && (
                          <div
                            style={{
                              width: "130px",
                              height: "130px",
                              background: "#ccc",
                              borderRadius: "50%",
                            }}
                          ></div>
                        )}
                      </div>
                    </div>
                    <div className="profile-head-btn">
                      <button
                        type="button"
                        id="uploadButton"
                        className="btn btn-light"
                        onClick={() =>
                          document.getElementById("imageUpload").click()
                        }
                      >
                        {imageButtonText}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-form-wrapper">
                <div className="form-group">
                  <label htmlFor="" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control Profile-input mt-1"
                    placeholder="Enter firstname"
                    value={firstname}
                    onChange={(e) => {
                      const capitalizedValue = capitalizeFirstLetter(
                        e.target.value
                      );
                      setFirstNameLocal(capitalizedValue);
                    }}
                    maxLength={10}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control Profile-input mt-1"
                    placeholder="Enter lastname"
                    value={lastname}
                    onChange={(e) => {
                      const capitalizedValue = capitalizeFirstLetter(
                        e.target.value
                      );
                      setLastNameLocal(capitalizedValue);
                    }}
                    maxLength={10}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control Profile-input mt-1"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="form-label">
                    Phone
                  </label>
                  <input
                    type="number"
                    className="form-control Profile-input mt-1"
                    placeholder="Enter phone number"
                    value={phone}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d{0,10}$/.test(value)) {
                        setPhone(value);
                      }
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="form-label">
                    DOB
                  </label>
                  <input
                    type="date"
                    className="form-control Profile-input mt-1"
                    placeholder="DOB"
                    value={dob}
                    onChange={(e) => setDOB(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="form-label">
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control Profile-input mt-1"
                    placeholder="Enter address one"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    maxLength={20}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control Profile-input mt-1"
                    placeholder="Enter address two"
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                    maxLength={15}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="form-label">
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control Profile-input mt-1"
                    placeholder="Enter state"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    maxLength={15}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="form-label">
                    Country
                  </label>
                  <input
                    type="text"
                    className="form-control Profile-input mt-1"
                    placeholder="Enter country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    maxLength={15}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="form-label">
                    ZIP Code
                  </label>
                  <input
                    type="text"
                    className="form-control Profile-input mt-1"
                    placeholder="Enter zip"
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                    maxLength={6}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="" className="form-label">
                    Description
                  </label>
                  <textarea
                    className="form-control"
                    id=""
                    rows="3"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    maxLength={100}
                  ></textarea>
                </div>
                {/* {error && <p className="text-danger">{error}</p>} */}
                <div className="profile-btn">
                  <button type="submit" className="btn">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Profile;
