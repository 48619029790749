import React from "react";
import NavBar from "../../../components/navbar/NavBar";

const Community = () => {
  return (
    <div>
      <NavBar />
      <div className="user-dashboard-inner mt-3">
        <h6>Welcome to Users Community</h6>
      </div>
    </div>
  );
};

export default Community;
