import React, { createContext, useState } from "react";

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [userProfileImage, setUserProfileImage] = useState(null);
  const [adminProfileImage, setAdminProfileImage] = useState(null);
  const [userFirstname, setUserFirstname] = useState("");
  const [userLastname, setUserLastname] = useState("");
  const [adminFirstname, setAdminFirstname] = useState("");
  const [adminLastname, setAdminLastname] = useState("");

  return (
    <ProfileContext.Provider
      value={{
        userProfileImage,
        setUserProfileImage,
        adminProfileImage,
        setAdminProfileImage,
        userFirstname,
        setUserFirstname,
        userLastname,
        setUserLastname,
        adminFirstname,
        setAdminFirstname,
        adminLastname,
        setAdminLastname,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
