export const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(String(email).toLowerCase());
};

export const validatePassword = (password) => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,12}$/;
  return passwordRegex.test(password);
};

export const validateConfirmPassword = (confirmPassword) => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,12}$/;
  return passwordRegex.test(confirmPassword);
};

export const validateFirstName = (firstname) => {
  const firstnameRegex = /^[a-zA-Z ]{3,16}$/;
  return firstnameRegex.test(firstname);
};

export const validateLastName = (lastname) => {
  const lastnameRegex = /^[a-zA-Z ]{3,16}$/;
  return lastnameRegex.test(lastname);
};

export const validateAddress = (address) => {
  const addressRegex = /^[a-zA-Z0-9\s,'-]*$/;
  return addressRegex.test(address);
};

export const validateCity = (city) => {
  const cityRegex = /^[a-zA-Z ]{3,10}$/;
  return cityRegex.test(city);
};

export const validateState = (state) => {
  const stateRegex = /^[a-zA-Z ]{3,20}$/;
  return stateRegex.test(state);
};

export const validateZip = (zip) => {
  const zipRegex = /^(00[5-9][0-9]{2}|0[1-9][0-9]{3}|[1-9][0-9]{4})(?:-[0-9]{4})?$/;
  return zipRegex.test(zip);
};

export const validatePhone = (phone) => {
  const phoneRegex = /^\+[1-9]{1}[0-9]{3,14}$/;
  return phoneRegex.test(phone);
};

export const validateNoNumbersOrSpecialChars = (input) => {
  const invalidCharsRegex = /[^a-zA-Z ]/; // Disallow numbers and special characters
  return !invalidCharsRegex.test(input);
};