import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../components/navbar/NavBar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DateRangePicker } from "rsuite";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { handleDownloadExcel } from "../../../../src/utils/excelHelper";
import Tooltip from "@mui/material/Tooltip";

const AdminSolPurchaseHistory = () => {
  const navigate = useNavigate();
  const [history, setHistory] = useState([]);
  const [filters, setFilters] = useState({
    createdAt: "",
    status: "",
    walletAddress: "",
    startDate: null,
    endDate: null,
  });

  const normalizeStartDate = (date) => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(0, 0, 0, 0);
    return normalizedDate;
  };

  const normalizeEndDate = (date) => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(23, 59, 59, 999);
    return normalizedDate;
  };

  useEffect(() => {
    const User = localStorage.getItem("user");
    const parsedUser = JSON.parse(User);
    const token = parsedUser.auth;

    const fetchSolPurchaseHistory = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT_URL}/solana/getBuySolanaAdminHistory`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setHistory(response.data.data);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };

    fetchSolPurchaseHistory();
  }, [navigate]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const onFilterChange = (e, field) => {
    const value = e.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, [field]: value }));
  };

  const filterHistory = () => {
    return history.filter((hist) => {
      const transactionDate = new Date(hist.createdAt);
      let dateMatch = true;

      if (filters.startDate && filters.endDate) {
        const startDate = normalizeStartDate(filters.startDate);
        const endDate = normalizeEndDate(filters.endDate);
        dateMatch = transactionDate >= startDate && transactionDate <= endDate;
      }
      const statusMatch = hist.status
        .toLowerCase()
        .includes(filters.status.toLowerCase());

      const walletMatch = hist.walletAddress
        .toLowerCase()
        .includes(filters.walletAddress.toLowerCase());

      return dateMatch && statusMatch && walletMatch;
    });
  };

  const onDateRangeChange = (range) => {
    if (range) {
      setFilters({
        ...filters,
        startDate: range[0],
        endDate: range[1],
      });
    } else {
      setFilters({
        ...filters,
        startDate: null,
        endDate: null,
      });
    }
  };

  const filteredHistory = filterHistory();

  const renderHeader = () => {
    return (
      <div className="table-search">
        <div className="row">
          <div className="col-lg-2 col-sm-12">
            <div className="table-search-inner">
              <DateRangePicker
                placeholder="Select Date Range"
                onChange={onDateRangeChange}
              />
            </div>
          </div>
          <div className="col-lg-2 col-sm-12">
            <div className="table-search-inner">
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Search by Status"
                  value={filters.status}
                  onChange={(e) => onFilterChange(e, "status")}
                />
                <InputGroup.Text id="basic-addon2">
                  <i className="fa fa-search"></i>
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
          <div className="col-lg-2 col-sm-12">
            <div className="table-search-inner">
              <InputGroup className="mb-3">
                <Form.Control
                  value={filters.walletAddress}
                  onChange={(e) => onFilterChange(e, "walletAddress")}
                  placeholder="Search by Wallet Address"
                />
                <InputGroup.Text id="basic-addon2">
                  <i className="fa fa-search"></i>
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
          <div className="col">
            <div className="table-download">
              <button
                className="btn"
                onClick={() =>
                  handleDownloadExcel(filteredHistory, "Solana_Transactions")
                }
              >
                <Tooltip title="Download Solana Transactions" arrow>
                  <i className="fa fa-file-excel"></i>
                </Tooltip>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <NavBar />
      <div className="sidebar-sec">
        <section id="content-wrapper">
          <div className="row">
            <div className="col-lg-12">
              <p className="content-title">Solana Transaction History</p>
            </div>
          </div>
          <div className="table-box sol-purchase">
            <DataTable
              value={filteredHistory}
              paginator
              rows={10}
              dataKey="id"
              responsiveLayout="scroll"
              header={renderHeader()}
            >
              <Column
                field="createdAt"
                header="Date of Purchase"
                body={(rowData) => formatDate(rowData.createdAt)}
                sortable
              />
              <Column field="status" header="Status" sortable />
              <Column field="baseCurrencyName" header="Base Currency Name" />
              <Column field="baseCurrencyCode" header="Base Currency Code" />
              <Column field="quoteCurrencyName" header="Quote Currency Name" />
              <Column field="quoteCurrencyCode" header="Quote Currency Code" />
              <Column
                field="quoteCurrencyNetworkCode"
                header="Quote Currency Network Code"
              />
              <Column field="moonpayTransactionId" header="MoonPay Txn ID" />
              <Column
                field="baseCurrencyAmount"
                header="Base Currency Amount"
              />
              <Column
                field="quoteCurrencyAmount"
                header="Quote Currency Amount"
              />
              <Column field="feeAmount" header="Fee Amount" />
              <Column field="extraFeeAmount" header="Extra Fee Amount" />
              <Column field="networkFeeAmount" header="Network Fee Amount" />
              <Column field="walletAddress" header="Wallet Address" sortable />
              <Column field="areFeesIncluded" header="Are Fee Included" />
            </DataTable>
          </div>
        </section>
      </div>
    </>
  );
};

export default AdminSolPurchaseHistory;
