import { NATIVE_MINT } from "@solana/spl-token";
import { Connection, PublicKey } from "@solana/web3.js";
import { getAssociatedAddress } from "../utils/helper";

export const TOKEN_A_DECIMALS = 1_000_000;

export const TOKEN_B_DECIMALS = 1_000_000_000;

// export const TOKEN_SWAP_PROGRAM_ID = new PublicKey(
//   "35JdJN4fCKPbeRfUdbj5TdUV33XrzPWmxEqp95kdxtn7"
// );

export const swap_authority = new PublicKey(
  "Azx4axUeJYWFmq7xbor2XRtUewGNbyosz8WK4pwuxveq"
);

export const fee_owner = new PublicKey(
  "AhaqZkm3CBJW8Fr2jxjWXa1bmueTyUaBF9GpRrWPzHdt"
);

// export const LP_TOKEN = new PublicKey(
//   "2EF83ZAb2XwxgPEfXYF6eK4jEHjsyw3c9JRyCwZk9uY1"
// );

export const LFC_PAIR = new PublicKey(
  "8K88szwNHoALxCozK2z4cnheKfoQdpFSHYz2SdL7r6uP"
);

export const rpc =
  "https://devnet.helius-rpc.com/?api-key=976801c8-1237-4e44-94e3-3f03f9a1212d";

export const connection = new Connection(rpc);

// export const tokenSwap = new PublicKey(
//   "2ivhPSdAEzRUACni8DPV2BkGo8398zRok7Ns3mF3bS2J"
// );

// export const swapAuthority = new PublicKey(
//   "8vG9uaUdGmWW18ZhThiaZ3zxiMVwA3vy3FRAdsmNRbqU"
// );

// export const lfcAccountAddress = new PublicKey(
//   "6toksb914VnJHAZ613j1tXwYiYYriDDrJUEbd3D5cANv"
// );

// export const wSolAccountAddress = new PublicKey(
//   "G7cUgpk9u3CnZ1ZXPwrmv8JiLptgFEkfkXADohohQLRE"
// );

export const TOKEN_SWAP_PROGRAM_ID = new PublicKey(
  "9L68fWPJQRdsyeSbLUy4nVjPkj9fLK9vuVhs6h91tqyN"
);

export const tokenSwap = new PublicKey(
  "HibdvsZvuKK7pHvp81Qik9y74q2rWvFELmA1b1NdwLb6"
);

export const swapAuthority = new PublicKey(
  "Azx4axUeJYWFmq7xbor2XRtUewGNbyosz8WK4pwuxveq"
);

export const LP_TOKEN = new PublicKey(
  "AVYQqk3PQQK53s9U2BUAEKFqdq4rTPSFLsMjvWH8t9yz"
);

export const lfcAccountAddress = await getAssociatedAddress(
  LFC_PAIR,
  swapAuthority
);

export const wSolAccountAddress = await getAssociatedAddress(
  NATIVE_MINT,
  swapAuthority
);
