import React from 'react';
import NavBar from '../../../components/navbar/NavBar';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

const AdminLFCDeposit = () => {
  return (
    <>
     <NavBar />
     <div className="user-dashboard-inner">
            <div className="user-dashboard-tab">
              <div className="container">
                <div className="user-dashboard-tab-inner">
                  <Tabs
                    defaultActiveKey="deposite"
                    id="uncontrolled-tab-example"
                    className=""
                  >
                    <Tab eventKey="deposite" title="Deposit">
                      <div className="user-dash-tab-form">
                        <Form.Label>
                          Sell Solana<span>*</span>
                        </Form.Label>
                        <InputGroup className="">
                          <Form.Control
                            type="number"
                            aria-label="Number input with dropdown button"
                            
                            name="sol"
                            placeholder="Solana amount"
                          />
                        </InputGroup>
                      </div>

                      {/* <div className="down-arrow-tab">
                        <i className="fa fa-arrow-down"></i>
                      </div> */}

                      <div className="user-dash-tab-form">
                       
                      </div>
                      <div className="connect-wallet-one">
                        <Button variant="">Submit</Button>
                      </div>
                      
                    </Tab>
                    <Tab eventKey="withdraw" title="Withdraw">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>To1</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Your Amount" className='mb-3'
                        />
                          <Form.Label>To2</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Your Amount" className='mb-3'
                        />
                      </Form.Group>
                      

                      <div className="connect-wallet-one">
                        <Button variant="">Submit</Button>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
    </>
  )
}

export default AdminLFCDeposit