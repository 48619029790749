import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Web3ReactProvider } from "@web3-react/core";
// import connectors from "./connector";
import { MoonPayProvider } from "@moonpay/moonpay-react";

import allConnections from "./connector";

const connections = allConnections.map(([connector, hooks]) => [
  connector,
  hooks,
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MoonPayProvider apiKey="pk_test_85oAqE977DZIg1k2sA1lX2OjKdEiA6o8" debug>
    <Web3ReactProvider connectors={connections}>
      {/* <React.StrictMode> */}
      <App />
      {/* </React.StrictMode> */}
    </Web3ReactProvider>
  </MoonPayProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
