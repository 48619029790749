//    For Testing Purposes only 
export const raw = [
    143, 158, 254, 48, 45, 13, 110, 63, 252, 108, 238, 228, 72, 99, 87, 125, 92,
    56, 247, 252, 52, 154, 183, 136, 237, 34, 14, 177, 100, 79, 107, 210, 6, 197,
    221, 143, 0, 134, 46, 47, 9, 122, 222, 109, 189, 80, 129, 191, 37, 249, 135,
    163, 83, 71, 120, 22, 63, 198, 236, 204, 219, 120, 254, 60,
  ];

  export const tokenState = [
    79, 250, 231, 144, 182, 29, 2, 60, 253, 183, 151, 65, 171, 54, 82, 177, 97,
    231, 21, 40, 16, 15, 99, 90, 252, 210, 35, 182, 2, 247, 104, 214, 6, 197, 223,
    251, 102, 46, 225, 216, 218, 185, 35, 28, 81, 238, 4, 146, 226, 199, 119, 90,
    112, 194, 16, 16, 55, 183, 177, 98, 208, 129, 199, 210,
  ];