import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Content from './Content';
import Head from './Head';
import '../../style.css';

export default function Main() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname !== '/launchparty') {
      navigate('/launchparty');
    }
  }, [location.pathname, navigate]);

  return (
    <div className='main-body-sec'>
      <Head />
      <Content />
    </div>
  );
}