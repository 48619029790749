import React, { useState, useEffect } from "react";
import BuyWidget from "../moonpay/MoonPayWidget";

const MoonPayView = ({
  userInput,
  wallet,
  isOpen,
  setIsOpen,
  setBuyAmount,
  walletAddress,
}) => {
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState('');

  const Email = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).email
    : "";

  const handleConnectWallet = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {wallet ? (
        <>
          <div className="wallet-input">
            <div className="connect-wallet">
              <div className="connect-wallet-one">
                <button onClick={() => userInput <= 0 ? setError('Please enter amount more than 0'): setError('') || setVisible(!visible)}>Buy Now</button>
              </div>
              {userInput ? (
                <BuyWidget
                  visible={visible}
                  email={Email}
                  walletAddress={walletAddress}
                  userInput={userInput}
                  setBuyAmount={setBuyAmount}
                />
              ) : (
                ""
              )}
            </div>
            {error && <p>{error}</p>}
          </div>
        </>
      ) : (
        <div className="connect-wallet-one">
          <button onClick={handleConnectWallet}>Connect Wallet</button>
        </div>
      )}
    </>
  );
};

export default MoonPayView;
