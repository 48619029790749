import React, { useState, useEffect } from "react";
import BigNumber from "bignumber.js";
import { TOKEN_A_DECIMALS, TOKEN_B_DECIMALS } from "../../constants/constants";
import { calculateExchangeRate } from "../../utils/helper";

const SwapInfo = ({
  inputAmount,
  setClicked,
  handleLiquidityPool,
  setInput,
  getExchangeRate,
  wallet,
  from,
}) => {
  const [exchangeRate, setExchangeRate] = useState(new BigNumber(0));
  const [swapFee, setSwapFee] = useState(new BigNumber(0));
  const [burnFee, setBurnfee] = useState(new BigNumber(0));
  const [preFee, setPreFee] = useState(new BigNumber(0));

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const [rate, fee, burn_fee, outputAmount] = await calculateExchangeRate(
      BigNumber(inputAmount),
      from,
      wallet
    );
    setExchangeRate(rate);
    if (from === "sol") {
      setInput({ sol: inputAmount, lfc: rate.toFixed(6) });
    } else {
      setInput({
        lfc: inputAmount,
        sol: rate.toFixed(6),
      });
    }
    if (from === "sol") {
      setSwapFee(fee / BigNumber(TOKEN_B_DECIMALS));
      setBurnfee(burn_fee / BigNumber(TOKEN_A_DECIMALS));
      const PRE_FEE = BigNumber(inputAmount).times(TOKEN_B_DECIMALS);
      setPreFee(PRE_FEE / BigNumber(TOKEN_B_DECIMALS));
    } else {
      setSwapFee(fee / BigNumber(TOKEN_A_DECIMALS));
      setBurnfee(burn_fee / BigNumber(TOKEN_A_DECIMALS));
      const PRE_FEE = BigNumber(inputAmount).times(TOKEN_A_DECIMALS);
      setPreFee(PRE_FEE / BigNumber(TOKEN_A_DECIMALS));
    }
  };

  const handleCLick = async () => {
    await handleLiquidityPool();
    setClicked(false);
    const flushInput = {
      sol: "",
      lfc: "",
    };
    setInput(flushInput);
  };

  return (
    <>
      {setClicked && (
        <div>
          <div>
            {/* <label>Input Amount SOL: </label> */}
            <label>Details: </label>
          </div>
          {from === "sol" ? (
            <>
              <p>Pre Fee Amount: {preFee.toFixed(6)} SOL</p>
              <p>Swap Fee: {swapFee.toFixed(6)} SOL</p>
              <p>
                Exchange Rate:{" "}
                {BigNumber(inputAmount).minus(swapFee).toFixed(6)} SOL ≈{" "}
                {exchangeRate.toFixed(6)} LFC
              </p>

              <p>Burn Fee: {burnFee.toFixed(6)} LFC</p>
            </>
          ) : (
            <>
              <p>Pre Fee Amount: {preFee.toFixed(6)} LFC</p>

              <p>Swap Fee: {swapFee.toFixed(6)} LFC</p>
              <p>
                Exchange Rate:{" "}
                {BigNumber(inputAmount).minus(swapFee).toFixed(6)} LFC ≈{" "}
                {exchangeRate.toFixed(6)} SOL
              </p>

              <p>Burn Fee: {burnFee.toFixed(6)} LFC</p>
            </>
          )}

          <div className="connect-wallet-one">
            <button onClick={handleCLick}> Proceed</button>
          </div>
        </div>
      )}
    </>
  );
};

export default SwapInfo;
