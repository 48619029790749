import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../components/navbar/NavBar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Swal from "sweetalert2";
import Tooltip from "@mui/material/Tooltip";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    phone: "",
    address: "",
  });

  const User = localStorage.getItem("user");
  const parsedUser = JSON.parse(User);
  const token = parsedUser.auth;

  useEffect(() => {
    if (parsedUser) {
      if (parsedUser && parsedUser.role) {
        if (parsedUser.role === "admin") {
          navigate("/admin-dashboard");
        } else if (parsedUser.role === "user") {
          navigate("/user-dashboard");
        } else {
          navigate("/");
        }
      }
    }

    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT_URL}/admin/getAllUsers`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setUsers(response.data.data);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };

    fetchUsers();
  }, [navigate]);

  const resendActivationLink = async (rowData) => {
    try {
      const userId = rowData._id;
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_URL}/admin/resendActivationEmail`,
        { userId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data.status === 200) {
        Swal.fire({
          title: "Success!",
          text: "Resend Activation link successful",
          icon: "success",
          confirmButtonText: "Ok",
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: `${response.data.status}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Failed to resend activation link:", error);
      Swal.fire({
        title: "Error!",
        text: "Something Went Wrong! Please try again",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const renderStatusColumn = (rowData) => {
    return (
      <div className="status-column">
        <span>{rowData.status ? "Active" : "Inactive"}</span>
        {rowData.status === false && (
          <Tooltip title="Resend Activation mail" arrow>
            <i
              className="fa fa-paper-plane send-button"
              onClick={() => resendActivationLink(rowData)}
            ></i>
          </Tooltip>
        )}
      </div>
    );
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const onFilterChange = (e, field) => {
    const value = e.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, [field]: value }));
  };

  const filterUsers = () => {
    return users.filter((user) => {
      const firstNameMatch = user.firstName
        ? capitalizeFirstLetter(user.firstName)
            .toLowerCase()
            .includes(filters.firstName.toLowerCase())
        : true;

      const lastNameMatch = user.lastName
        ? capitalizeFirstLetter(user.lastName)
            .toLowerCase()
            .includes(filters.lastName.toLowerCase())
        : true;

      const emailMatch = user.email
        ? user.email.toLowerCase().includes(filters.email.toLowerCase())
        : true;

      const roleMatch = user.role
        ? user.role.toLowerCase().includes(filters.role.toLowerCase())
        : true;

      const phoneMatch = user.phone
        ? String(user.phone).toLowerCase().includes(filters.phone.toLowerCase())
        : true;

      const addressMatch = user.address
        ? user.address.toLowerCase().includes(filters.address.toLowerCase())
        : false;

      return (
        firstNameMatch &&
        lastNameMatch &&
        emailMatch &&
        roleMatch &&
        phoneMatch &&
        (filters.address ? addressMatch : true)
      );
    });
  };

  const filteredUsers = filterUsers();

  const renderHeader = () => {
    return (
      <div className="table-search">
        <div className="row">
          <div className="col-lg-2 col-sm-12">
            <div className="table-search-inner">
              <InputGroup className="mb-3">
                <Form.Control
                  value={filters.firstName}
                  onChange={(e) => onFilterChange(e, "firstName")}
                  placeholder="Search by First Name"
                />
                <InputGroup.Text id="basic-addon2">
                  <i className="fa fa-search"></i>
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
          <div className="col-lg-2 col-sm-12">
            <div className="table-search-inner">
              <InputGroup className="mb-3">
                <Form.Control
                  value={filters.lastName}
                  onChange={(e) => onFilterChange(e, "lastName")}
                  placeholder="Search by Last Name"
                />
                <InputGroup.Text id="basic-addon2">
                  <i className="fa fa-search"></i>
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
          <div className="col-lg-2 col-sm-12">
            <div className="table-search-inner">
              <InputGroup className="mb-3">
                <Form.Control
                  value={filters.email}
                  onChange={(e) => onFilterChange(e, "email")}
                  placeholder="Search by Email"
                />
                <InputGroup.Text id="basic-addon2">
                  <i className="fa fa-search"></i>
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
          <div className="col-lg-2 col-sm-12">
            <div className="table-search-inner">
              <InputGroup className="mb-3">
                <Form.Control
                  value={filters.role}
                  onChange={(e) => onFilterChange(e, "role")}
                  placeholder="Search by Role"
                />
                <InputGroup.Text id="basic-addon2">
                  <i className="fa fa-search"></i>
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
          <div className="col-lg-2 col-sm-12">
            <div className="table-search-inner">
              <InputGroup className="mb-3">
                <Form.Control
                  value={filters.phone}
                  onChange={(e) => onFilterChange(e, "phone")}
                  placeholder="Search by Phone"
                />
                <InputGroup.Text id="basic-addon2">
                  <i className="fa fa-search"></i>
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
          <div className="col-lg-2 col-sm-12">
            <div className="table-search-inner">
              <InputGroup className="mb-3">
                <Form.Control
                  value={filters.address}
                  onChange={(e) => onFilterChange(e, "address")}
                  placeholder="Search by Address"
                />
                <InputGroup.Text id="basic-addon2">
                  <i className="fa fa-search"></i>
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <NavBar />
      <div className="sidebar-sec">
        <section id="content-wrapper">
          <div className="row">
            <div className="col-lg-12">
              <p className="content-title">Users</p>
            </div>
          </div>
          <div className="table-box user-table">
            <DataTable
              value={filteredUsers}
              paginator
              rows={10}
              dataKey="id"
              responsiveLayout="scroll"
              header={renderHeader()}
            >
              <Column
                field="firstName"
                header="First Name"
                body={(rowData) => capitalizeFirstLetter(rowData.firstName)}
                sortable
              />
              <Column
                field="lastName"
                header="Last Name"
                body={(rowData) => capitalizeFirstLetter(rowData.lastName)}
                sortable
              />
              <Column field="email" header="Email" sortable />
              <Column field="role" header="Role" sortable />
              <Column field="phone" header="Phone" sortable />
              <Column field="address" header="Address" sortable />
              <Column
                field="status"
                header="Status"
                body={renderStatusColumn}
                style={{ width: "150px" }}
                sortable
              />
            </DataTable>
          </div>
        </section>
      </div>
    </>
  );
};

export default AdminDashboard;
