import React, {useState} from "react";
import logo from "../../assets/images/logo-img.png";
import { Link, NavLink } from "react-router-dom";
import pdfFile from '../../assets/images/lfcoin.pdf'
import loyalPdf from '../../assets/images/LoyalsWhitepaper.pdf'

export default function Head() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);

  const handelWhitepaperOpen = () =>{
    window.open(pdfFile,'_blank')
  }

  // const handelLoyalsOpen = () =>{
  //   window.open(loyalPdf,'_blank')
  // }


  // const handleOpenNewTab = (event) => {
  //   event.preventDefault();
  //   window.open('/login', '_blank');
  // };

  // const pdfUrl = '../../assets/images/lfcoin.pdf'

  return (
    <>
      <header className="home-header">
        <div
          className={click ? "main-container" : ""}
          onClick={() => Close()}
        />
        <nav className="home-navbar" onClick={(e) => e.stopPropagation()}>
          <div className="nav-container">
            <NavLink exact to="/" className="home-nav-logo">
              <img src={logo} alt="logo" />
            </NavLink>
            <ul className={click ? "home-nav-menu active" : "home-nav-menu"}>          
              <li>
                <button onClick={handelWhitepaperOpen} type="button" className="btn cstm-btn-one">
                 <span>LFCoin Whitepaper</span>
                 <i className="fa-solid fa-download"></i>
                </button>
              </li>
              {/* <li>
                <button onClick={handelLoyalsOpen} type="button" className="btn cstm-btn-one">
                 <span>LAL Whitepaper</span>
                 <i className="fa-solid fa-download"></i>
                </button>
              </li>
              <li>
                <Link to='/login' onClick={handleOpenNewTab}>
                  <button type="button" className="btn cstm-btn-two">
                    Login
                  </button>
                </Link>
              </li> */}
            </ul>

            <div className="home-nav-icon" onClick={handleClick}>
              <i className={click ? "fa fa-times" : "fa fa-bars"}></i>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}
