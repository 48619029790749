import React from 'react'
import NavBar from '../../../components/navbar/NavBar';

const RoadMap = () => {
  return (
    <div>
        <NavBar />
        <div className="user-dashboard-inner mt-3">
        <h6>Road Map to Get Started</h6>
        </div>
    </div>
  )
}

export default RoadMap;