import React, { useState } from "react";
import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePhone,
  validateNoNumbersOrSpecialChars,
} from "../../utils/validation";
import lineImage from "../../assets/images/line-art-large.png";
import Swal from "sweetalert2";
import axios from "axios";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ImageGif from "../../assets/images/Player_24_Emote.gif";

const Content = () => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorCouponCode, setErrorCouponCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [errorAcceptance, setErrorAcceptance] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isCouponUsed, setIsCouponUsed] = useState(false);
  const navigate = useNavigate();

  const handleSignUp = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateFirstName(firstname)) {
      setErrorFirstName(
        firstname.length > 0 && firstname.length < 3
          ? "First Name must be at least 3 characters long"
          : "Please enter first name"
      );
      setLoading(false);
      return;
    }

    if (!validateLastName(lastname)) {
      setErrorLastName(
        lastname.length > 0 && lastname.length < 3
          ? "Last Name must be at least 3 characters long"
          : "Please enter last name"
      );
      setLoading(false);
      return;
    }

    if (!validateEmail(email)) {
      setErrorEmail(
        email.length > 0 ? "Invalid email format" : "Please enter email"
      );
      setLoading(false);
      return;
    }

    if (phone && phone !== "+1") {
      console.log("phone", phone);
      if (!validatePhone(phone)) {
        setErrorPhone(
          phone.length > 0 ? "Invalid phone format" : "Please enter phone"
        );
        setLoading(false);
        return;
      }
    }

    if (!couponCode) {
      setErrorCouponCode("Please enter a coupon code");
      setLoading(false);
      return;
    }

    if (!isAccepted) {
      setErrorAcceptance("Please accept the terms and conditions");
      setLoading(false);
      return;
    }

    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT_URL}/users/validateCouponCode/${couponCode}`
      )
      .then((response) => {
        if (response.data.status !== 200) {
          console.log("heloooooooooooooooooooo", response.data.message);
          setErrorCouponCode(
            response.data.message === "Your coupon code is already used"
              ? "This coupon code has already been used."
              : "Invalid Coupon Code"
          );
          setLoading(false);
          return;
        }

        const userData = {
          firstname,
          lastname,
          phone,
          email,
          couponCode,
        };

        axios
          .post(
            `${process.env.REACT_APP_API_ENDPOINT_URL}/users/promotionalSignup`,
            userData
          )
          .then((response) => {
            if (response.data.status === 200) {
              setIsSuccess(true);
              setIsCouponUsed(true);
              setLoading(false);
              setTimeout(() => {
                setFirstName("");
                setLastName("");
                setPhone("");
                setEmail("");
                setCouponCode("");
                setErrorFirstName("");
                setErrorLastName("");
                setErrorPhone("");
                setErrorEmail("");
                setErrorCouponCode("");
                setIsAccepted(false);
                setErrorAcceptance("");
              }, 8000);
            } else {
              Swal.fire({
                title: "Error!",
                text: `${response.data.message}`,
                icon: "error",
                confirmButtonText: "OK",
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error!",
              text: "Something Went Wrong! Please try again",
              icon: "error",
              confirmButtonText: "OK",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        setErrorCouponCode("Invalid Coupon Code");
        setLoading(false);
      });
  };

  const handelHome = () => {
    setIsSuccess(false);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const CouponSuccessMessage = () => (
    <>
      <div className="ani-image-home">
        <img src={lineImage} alt="img" />
      </div>
      <div className="home-btn-thanks">
        <button className="home-btn" type="button" onClick={handelHome}>
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
      </div>

      <div className="lend-cont-thanks">
        <div className="container">
          <div className="lend-cont-thanks-head">
            <h4>WELCOME TO THE COMMUNITY ! </h4>
            <div className="ifcoin-gif">
              <img src={ImageGif} alt="lfcoin" />
            </div>
            <p>
              Don't have a Solana wallet yet? No worries! You'll need one to
              receive your $LAL coins, and we'll provide you with a guide to
              help you set it up. Stay tuned, and thank you for joining the
              LFCoin community!
            </p>
            <p>Please check your email for further details.</p>
          </div>
          <div className="iframe-video">
            <iframe
              width=""
              height="400"
              src="https://www.youtube.com/embed/7V_Phj2Ndb4?si=ikAAN6MAZZLj1XiM"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
          <div className="lend-cont-thanks-inner-in">
            <p>
              <strong>Disclosure: </strong>
              LFCOIN $LAL is a deflationary MEME COIN. LFCOIN $LAL is not
              affiliated with the Los Angeles Lakers or the National Basketball
              Association (NBA). $LAL is not a security and carries no intrinsic
              value. Its primary purpose is to serve as a utility token within a
              crypto community, supporting engagement and interactions.
            </p>
          </div>
        </div>
      </div>
    </>
  );

  const SuccessMessage = () => (
    <>
      <div className="ani-image-home">
        <img src={lineImage} alt="img" />
      </div>

      <div className="home-btn-thanks">
        <button className="home-btn" type="button" onClick={handelHome}>
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
      </div>

      <div className="lend-cont-thanks">
        <div className="lend-cont-thanks-head">
          <h4>Thank you for Registration!</h4>
          <h3>Congratulations! </h3>
          <h5>You've been selected for 100,000 $LAL</h5>
          <p>Please check your email for further details.</p>
        </div>
        <div className="lend-cont-thanks-inner">
          <h4>Disclosure</h4>
          <p>
            LFCOIN $LAL is a deflationary MEME COIN. LFCOIN $LAL is not
            affiliated with the Los Angeles Lakers or the National Basketball
            Association (NBA). $LAL is not a security and carries no intrinsic
            value. Its primary purpose is to serve as a utility token within a
            crypto community, supporting engagement and interactions.
          </p>
        </div>
      </div>
    </>
  );

  return (
    <>
      {!isSuccess && (
        <div className="home-container home-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="lend-cont">
                  <h3>Launch Party</h3>
                  <div className="lend-cont-inner">
                    <h4>LFCoin ($LAL)</h4>
                    <p>
                      is a community-driven cryptocurrency built on the Solana
                      blockchain, designed to engage and reward a passionate
                      community of sports fans. Through staking rewards, a
                      deflationary burn mechanism tied to major sports events,
                      and participation in games and activities, LFCoin offers
                      fans a unique way to celebrate sports fandom. This
                      whitepaper outlines the technical specifications,
                      tokenomics, and utility of LFCoin, showcasing how the
                      ecosystem operates and benefits holders.
                    </p>
                    <h4>Congratulations!</h4>
                    <p>
                      You've Been Selected to Receive 100,000 $LAL! We are
                      thrilled to announce that you've been selected as one of
                      the lucky participants in our LFCoin promotion. You will
                      receive 100,000 $LAL coins as part of this exclusive
                      giveaway!
                    </p>
                    <h4>What's next?</h4>
                    <p>
                      Simply fill out the form with your information, and we'll
                      notify you as soon as your coins are available for
                      claiming.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="laker-fan-coin-form">
                  <form onSubmit={handleSignUp}>
                    <h3>Sign up to Join Community</h3>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-box">
                          <label htmlFor="firstName">
                            First Name<span className="color-asterisk">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            name="firstName"
                            placeholder="First Name"
                            value={firstname}
                            // onChange={(e) => {
                            //   const capitalizedValue = capitalizeFirstLetter(
                            //     e.target.value
                            //   );
                            //   setFirstName(capitalizedValue);
                            //   setErrorFirstName("");
                            // }}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (validateNoNumbersOrSpecialChars(inputValue)) {
                                const capitalizedValue =
                                  capitalizeFirstLetter(inputValue);
                                setFirstName(capitalizedValue);
                                setErrorFirstName("");
                              } else {
                                setErrorFirstName(
                                  "Only alphabet characters are allowed."
                                );
                              }
                            }}
                            maxLength={16}
                          />
                          <span className="fa-solid fa-user field-icon"></span>
                        </div>
                        {errorFirstName && (
                          <div className="validation-mess">
                            <p className="text-danger">{errorFirstName}</p>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-box">
                          <label htmlFor="lastName">
                            Last Name<span className="color-asterisk">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            name="lastName"
                            placeholder="Last Name"
                            value={lastname}
                            // onChange={(e) => {
                            //   const capitalizedValue = capitalizeFirstLetter(
                            //     e.target.value
                            //   );
                            //   setLastName(capitalizedValue);
                            //   setErrorLastName("");
                            // }}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (validateNoNumbersOrSpecialChars(inputValue)) {
                                const capitalizedValue =
                                  capitalizeFirstLetter(inputValue);
                                setLastName(capitalizedValue);
                                setErrorLastName("");
                              } else {
                                setErrorLastName(
                                  "Only alphabet characters are allowed."
                                );
                              }
                            }}
                            maxLength={16}
                          />
                          <span className="fa-solid fa-user field-icon"></span>
                        </div>
                        {errorLastName && (
                          <div className="validation-mess">
                            <p className="text-danger">{errorLastName}</p>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="form-box">
                          <label htmlFor="email">
                            Email<span className="color-asterisk">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setErrorEmail("");
                            }}
                            maxLength={40}
                          />
                          <span className="fa-solid fa-envelope field-icon"></span>
                        </div>
                        {errorEmail && (
                          <div className="validation-mess">
                            <p className="text-danger">{errorEmail}</p>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="custom-phone-input">
                          <label htmlFor="phone">Phone</label>
                          <PhoneInput
                            className="custom-phone-input-btn"
                            defaultCountry="us"
                            value={phone}
                            onChange={(phone) => {
                              setPhone(phone);
                              setErrorPhone("");
                            }}
                          />
                          <span className="fa-solid fa-phone field-icon"></span>
                        </div>
                        {errorPhone && (
                          <div className="validation-mess">
                            <p className="text-danger">{errorPhone}</p>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="form-box mt-3">
                          <label htmlFor="couponCode">
                            Coupon Code<span className="color-asterisk">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="couponCode"
                            name="couponCode"
                            placeholder="Coupon Code"
                            value={couponCode}
                            onChange={(e) => {
                              setCouponCode(e.target.value);
                              setErrorCouponCode("");
                            }}
                            maxLength={32}
                          />
                        </div>
                        {errorCouponCode && (
                          <div className="validation-mess">
                            <p className="text-danger">{errorCouponCode}</p>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div style={{ display: "flex" }}>
                          <div
                            style={{ marginRight: "10px", marginTop: "4px" }}
                          >
                            <input
                              type="checkbox"
                              id="acceptance"
                              checked={isAccepted}
                              onChange={(e) => {
                                setIsAccepted(e.target.checked);
                                setErrorAcceptance("");
                              }}
                            />
                          </div>
                          <div>
                            <label htmlFor="acceptance">
                              By participating in the $LAL giveaway, you agree
                              that $LAL coins are promotional and not linked to
                              any other offers or obligations. They hold no
                              guaranteed value, and $LAL is not liable for any
                              losses or issues arising from receiving or using
                              the coins. No legal claims or recourse will be
                              accepted
                            </label>
                          </div>
                        </div>
                        {errorAcceptance && (
                          <div className="validation-mess">
                            <p className="text-danger">{errorAcceptance}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="cstm-login-btn">
                      <button
                        type="submit"
                        className="btn w-100"
                        disabled={loading}
                      >
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="lend-cont-inner-in">
                    <p>
                      {" "}
                      <strong>Disclosure: </strong>
                      LFCOIN $LAL is a deflationary MEME COIN. LFCOIN $LAL is
                      not affiliated with the Los Angeles Lakers or the National
                      Basketball Association (NBA). $LAL is not a security and
                      carries no intrinsic value. Its primary purpose is to
                      serve as a utility token within a crypto community,
                      supporting engagement and interactions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ani-image-home">
            <img src={lineImage} alt="img" />
          </div>
        </div>
      )}
      {isSuccess ? (
        isCouponUsed ? (
          <CouponSuccessMessage />
        ) : (
          <SuccessMessage />
        )
      ) : null}
      <div className="main-footer">
        <p>
          <i className="fa fa-copyright"></i> Copyright 2024 predesigned All
          rights Received
        </p>
      </div>
    </>
  );
};

export default Content;
